import React from 'react';
import Stack from '@mui/material/Stack';
import { Link, PositiveButton, Radio } from 'Component/Button';
import { FormattedMessage } from 'react-intl';
import Guided from 'Views/query/Guided/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { clear, setTable, setTypeQuery } from 'Slice/query';
import { HTTP_STATUS } from 'Asset/constants';
import { Divider, Domain } from 'Component/index';
import { setDomain } from 'Slice/domain';
import Manual from 'Views/query/manual/Filter';

function Filter() {
  const {
    type, table, loading, tables,
  } = useSelector((s) => s.query);
  const dispatch = useDispatch();
  const onChange = () => dispatch(setTypeQuery());
  const handleChange = (event) => {
    dispatch(setTable(event.target.value));
  };
  const reset = () => {
    dispatch(clear());
    dispatch(setDomain(''));
  };
  return (
    <>
      <Stack sx={{ flexWrap: 'wrap', justifyContent: 'center', mb: 1 }} direction="row">
        <PositiveButton sx={{ mr: 1 }} disabled={!type} onClick={onChange}>
          <FormattedMessage id="guided" />
        </PositiveButton>
        <PositiveButton disabled={type} onClick={onChange}>
          <FormattedMessage id="manual" />
        </PositiveButton>
      </Stack>
      {table && !type && <Link disabled label="reset" onClick={reset} />}
      <Stack spacing={2} direction={type ? 'row' : 'column'}>

        <Radio
          translate={!type}
          handleChange={handleChange}
          title="select.table"
          value={table}
          loading={loading === HTTP_STATUS.PENDING}
          data={tables}
        />
        <Divider />
        <Domain disabled={type} />
      </Stack>
      { !type && <Guided /> }
      { type
      && (
      <>
        <Divider />
        <Manual />
      </>
      ) }
    </>
  );
}

export default Filter;
