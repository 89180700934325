import React from 'react';
import { Logged } from 'Component/ViewContainer';
import Content from 'Views/query/Content';
import Filter from 'Views/query/Filter';
import { useSelector } from 'react-redux';

function View() {
  const { type } = useSelector((s) => s.query);
  return (
    <Logged
      isMiddle={type}
      content={<Content />}
      filter={<Filter />}
    />
  );
}

export default View;
