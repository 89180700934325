import React from 'react';
import Guided from 'Views/query/Guided/Content';
import { useSelector } from 'react-redux';
import Manual from 'Views/query/manual/Content';
import Stack from '@mui/material/Stack';
import { HTTP_STATUS } from 'Asset/constants';
import { Divider } from 'Component/index';
import DataGrid from 'Component/Datagrid/DataTable';

function Content() {
  const { type } = useSelector((s) => s.query);
  const {
    resultLoading, resultQuery,
  } = useSelector((s) => s.query);
  return (
    <Stack>
      { !type && <Guided />}
      { type && <Manual />}
      {(resultLoading === HTTP_STATUS.FULFILLED || resultLoading === HTTP_STATUS.PENDING)
        && (
        <>
          <Divider />
          <DataGrid
            rows={resultQuery ? resultQuery.rows : []}
            loading={resultLoading === HTTP_STATUS.PENDING}
            notData={(resultLoading === HTTP_STATUS.FULFILLED
                      && resultQuery.rows.length === 0)}
            columns={resultQuery ? resultQuery.columns : []}
          />
        </>
        )}
    </Stack>
  );
}

export default Content;
