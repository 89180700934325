import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import Toast from 'Component/Toast';
import Stack from '@mui/material/Stack';
import ToolTip from 'Component/Tooltip';
import { FormattedMessage } from 'react-intl';

function Copy({ text, tooltip, sx = {} }) {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        setOpen(false);
      }, 4500);
      return () => clearTimeout(timeout);
    }
    return null;
  }, [open]);
  return (
    <Stack alignItems="flex-end" sx={{ cursor: 'pointer', ...sx }}>
      <Toast open={open} message="text.copied" severity="success" />
      <CopyToClipboard text={text} onCopy={() => setOpen(true)}>
        <ToolTip title={<FormattedMessage id={tooltip} />} placement="top">
          <FileCopyIcon
            elevation={3}
            sx={{ color: (t) => t.palette.btn.positive.main, width: 25, height: 25 }}
          />
        </ToolTip>
      </CopyToClipboard>
    </Stack>

  );
}

export default Copy;
