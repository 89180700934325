import React from 'react';
import Typography from '@mui/material/Typography';

function FontTitle({ font = 20, children }) {
  return (
    <Typography sx={{
      fontSize: font,
      mt: 1,
      color: (t) => t.palette.white.main,
      fontWeight: '500',
    }}
    >
      {children}
    </Typography>
  );
}

export default FontTitle;
