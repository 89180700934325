import React from 'react';
import Stack from '@mui/material/Stack';
import { List, Radio } from 'Component';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'Slice/log';

function ListOrganization({ data }) {
  const dispatch = useDispatch();
  const MAX = process.env.MAX_FIELD_SELECT;
  const [local, setLocal] = React.useState([]);
  const [localR, setLocalR] = React.useState([]);
  const { filter } = useSelector((s) => s.log);

  const setData = () => {
    if (data && data.length > 0) {
      if (data.length > MAX) {
        setLocal(data.map((it) => it.name));
      } else {
        setLocalR(data.map((it) => ({ value: it.id, label: it.name })));
      }
    }
  };

  const handleChange = (e) => {
    const v = e.target.value;
    if (data.length > MAX) {
      const act = data.filter((it) => it.name === v);
      dispatch(setFilter({ ...filter, name: v, id: act[0].id }));
    } else {
      dispatch(setFilter({ ...filter, id: v }));
    }
  };
  React.useEffect(setData, [data]);
  if (data && data.length > 0) {
    if (data.length > MAX) {
      return (
        <Stack sx={{ minWidth: 200 }}>
          <List translate={false} data={local} value={filter.name} handleChange={handleChange} title="organization" />
        </Stack>
      );
    }
    return (
      <Radio
        data={localR}
        handleChange={handleChange}
        title="organization"
        value={filter.id}
      />
    );
  }
  return null;
}

export default ListOrganization;
