import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Radio } from 'Component';
import { DOMAIN_LOG_OPTIONS } from 'Asset/constants';
import { setFilter } from 'Slice/log';
import Stack from '@mui/material/Stack';

function ListDomain({ data }) {
  const [list, setList] = React.useState([]);
  const [local, setLocal] = React.useState([]);
  const [localR, setLocalR] = React.useState([]);
  const [active, setActive] = React.useState('');
  const MAX = process.env.MAX_FIELD_SELECT;
  const dispatch = useDispatch();
  const { filter } = useSelector((s) => s.log);
  const setData = () => {
    if (data && filter.id) {
      setLocalR([]);
      setLocal([]);
      setActive('');
      setList(data.filter((it) => it.id === filter.id)[0].domains);
    }
  };

  const setSelected = () => {
    dispatch(setFilter({ ...filter, domain: '' }));
    if (list.length > 0 && active !== '') {
      const act = list.filter((x) => x.status === active);

      if (act.length > MAX) {
        setLocalR([]);
        setLocal(act.map((it) => it.name));
      } else {
        setLocal([]);
        setLocalR(act.map((it) => ({ label: it.name, value: it.name })));
      }
    }
  };
  React.useEffect(setData, [data, filter.id]);
  React.useEffect(setSelected, [list, active]);
  const RadioActive = (
    <Stack sx={{ mb: 1 }}>
      <Radio
        data={DOMAIN_LOG_OPTIONS}
        title="domain.status"
        value={active}
        handleChange={(w) => setActive(w.target.value)}
      />
    </Stack>
  );

  const onSelected = (e) => {
    const v = e.target.value;
    dispatch(setFilter({ ...filter, domain: v }));
  };
  return (
    <Stack sx={{ mt: 1 }}>
      { RadioActive }
      {local.length > 0 && active && <List value={filter.domain} data={local} title="domain" translate={false} handleChange={onSelected} />}
      {localR.length > 0 && active && <Radio value={filter.domain} data={localR} title="domain" translate={false} handleChange={onSelected} />}
    </Stack>
  );
}

export default ListDomain;
