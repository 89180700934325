import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

import DataGrid from 'Component/Datagrid/DataTable';
import { COLUMNS_NOTES, HTTP_STATUS } from 'Asset/constants';
import Divider from 'Component/Divider/Divider';
import { FontTitle } from 'Component/Font';
import { FormattedMessage } from 'react-intl';
import { GridLoading, Toast } from 'Component';
import { ContentHook } from 'Hooks/Log/Content.hook';
import Note from 'Views/log/component/Note';

function Content() {
  const {
    dataNotes, loadingFind, createNote, loadingCreate,
  } = useSelector((s) => s.log);
  const {
    msg, opT, viewNote, onChange, onChangeValue, urgent, value, onSend, svr,
  } = ContentHook();
  return (
    <Stack spacing={1}>
      <Toast message={msg} open={opT} severity={svr} />
      { loadingCreate === HTTP_STATUS.PENDING && <GridLoading />}
      {viewNote && createNote && loadingCreate !== HTTP_STATUS.PENDING && (
        <>
          <Note
            onChange={onChange}
            setValue={onChangeValue}
            urgent={urgent}
            value={value}
            onSend={onSend}
          />
          <Divider />
        </>
      )}
      { !loadingFind && !viewNote && (
      <>
        <FontTitle>
          <FormattedMessage id="ingress.filter" />
        </FontTitle>
        <Divider />
      </>
      )}
      {(loadingFind === HTTP_STATUS.FULFILLED || loadingFind === HTTP_STATUS.PENDING)
        && (
        <DataGrid
          rows={dataNotes}
          loading={loadingFind === HTTP_STATUS.PENDING}
          notData={(loadingFind === HTTP_STATUS.FULFILLED && dataNotes.length === 0)}
          columns={COLUMNS_NOTES}
        />
        )}
    </Stack>
  );
}

export default Content;
