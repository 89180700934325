import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Auth } from 'aws-amplify';
import { addNote } from 'Slice/log';
import { HTTP_STATUS } from 'Asset/constants';

export function ContentHook() {
  const dispatch = useDispatch();
  const {
    filter, action, loadingCreate,
  } = useSelector((s) => s.log);
  const [urgent, setUrgent] = React.useState(false);
  const [value, setValue] = React.useState('');

  const onChange = (e) => {
    setUrgent(e.target.checked);
  };
  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  const onSend = async () => {
    const { attributes: { email } } = await Auth.currentUserInfo();
    const data = {
      domain: filter.domain,
      id: filter.id,
      note: value,
      type: value ? 'UR' : 'RG',
      user: email,
    };
    dispatch(addNote(data));
  };
  const viewNote = filter && filter.id !== '' && filter.domain !== '' && action === 'filter';
  const [msg, setMsg] = React.useState('login');
  const [opT, setOpt] = React.useState(false);
  const [svr, setSvr] = React.useState('success');

  React.useEffect(() => {
    if (loadingCreate === HTTP_STATUS.FULFILLED) {
      setOpt(true);
      setMsg('note.created');
      setSvr('success');
    }
    if (loadingCreate === HTTP_STATUS.REJECTED) {
      setOpt(true);
      setMsg('note.created.error');
      setSvr('error');
    }
  }, [loadingCreate]);
  React.useEffect(() => {
    if (opT) {
      const timeout = setTimeout(() => {
        setOpt(false);
        setMsg('empty');
        setSvr('success');
      }, 4500);
      return () => clearTimeout(timeout);
    }
    return null;
  }, [opT]);
  return {
    msg, opT, viewNote, onChange, onChangeValue, urgent, value, onSend, svr,
  };
}
