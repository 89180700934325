import React from 'react';
import { Logged } from 'Component';
import Content from './Content';
import Filter from './Filter';

function View() {
  return (
    <Logged
      content={<Content />}
      filter={<Filter />}
    />

  );
}

export default View;
