import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const BoxComment = styled(TextField)({

  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    padding: 0,
  },

  '& .MuiOutlinedInput-input': {
    borderWidth: 0,
    fontSize: 20,
    minHeight: 150,
    outline: 'none',
    padding: 20,
  },
});
export default BoxComment;
