import React from 'react';
import { AccountCircle, Login, VpnKey } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import {
  CustomPaper, PositiveButton, NoLogged, TextInput, Toast, Loading, Check,
} from 'Component';

import logo from 'Asset/images/logos/logo-text.png';
import { LoginHook } from 'Hooks/Login/LoginHook';

function View() {
  const {
    errorEmail, email, onChangeEmail, onBlur, focusEmail, msgError,
    errorPsw, onChangePsw, focusPsw, saveLogin, onSave, onLogin, isDisabled, open, psw, loading,
  } = LoginHook();
  if (loading) {
    return (
      <NoLogged>
        <CustomPaper elevation={12}>
          <Loading variant="circular" width={140} height={140} />
          <Grid
            container
            justifyContent="center"
            sx={{ mt: 3 }}
          >

            <Grid item md={9}>
              <Loading height={30} width="100%" />

              <Loading height={30} width="100%" />
            </Grid>
          </Grid>
        </CustomPaper>
      </NoLogged>
    );
  }
  return (
    <NoLogged>
      <Toast severity="error" message={msgError} open={open} />
      <CustomPaper elevation={3}>
        <img alt="logo" style={{ height: 120, marginBottom: 15 }} src={logo} width={140} />
        <Grid
          container
          justifyContent="center"
        >
          <Grid item md={9}>
            <TextInput
              error={errorEmail}
              value={email}
              label={<FormattedMessage id="username" />}
              onChange={onChangeEmail}
              onBlur={() => onBlur(true, true)}
              onFocus={() => onBlur(true, false)}
              size="small"
              nofocus={focusEmail}
              fullWidth
              margin="dense"
              InputProps={{
                autoComplete: 'off',
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              error={errorPsw}
              type="password"
              value={psw}
              label={<FormattedMessage id="password" />}
              onChange={onChangePsw}
              onBlur={() => onBlur(false, true)}
              onFocus={() => onBlur(false, false)}
              size="small"
              nofocus={focusPsw}
              fullWidth
              mb={1.2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
            <Check label="save.username" onChange={onSave} checked={saveLogin} />
          </Grid>
        </Grid>

        <PositiveButton onClick={onLogin} disabled={isDisabled}>
          <FormattedMessage id="login" />
          <Login />
        </PositiveButton>
      </CustomPaper>
    </NoLogged>
  );
}

export default View;
