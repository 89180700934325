import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    saveLogin: JSON.parse(localStorage.getItem('saveLogin')) || false,
    isLogged: false,
  },
  reducers: {
    setSaveLogin: (state, action) => {
      localStorage.setItem('saveLogin', action.payload);
      state.saveLogin = action.payload;
    },
    setLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    logout: () => {
      try {
        Auth.signOut();
      } catch (e) {
        console.log('user deslogeado');
      }
    },
  },

});
export const { setSaveLogin, logout, setLogged } = userSlice.actions;
export default userSlice.reducer;
