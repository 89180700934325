import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { MenuIcon } from 'Component/Navigation/Menu/itemsConfig';
import { v4 as uuid } from 'uuid';

class MapperClass {
  RadioButtonItems(data) {
    return data.map((item) => ({ value: item, label: item }));
  }

  GetNameFields(data) {
    return data.map(({ Name }) => (Name));
  }

  GetNotes(data) {
    return data.map((it) => {
      if (it.type === 'UR') return { ...it, priority: MenuIcon(PriorityHighIcon) };
      return { ...it, priority: MenuIcon(LowPriorityIcon) };
    });
  }

  SetTableQuery(data) {
    const columns = [];
    const rows = [];
    if (data && data.body && data.body.length > 1) {
      data.body[0].Data.forEach(({ VarCharValue: val }) => {
        columns.push(
          {
            field: val,
            headerName: val,
            translate: true,
            sorteable: false,
            headerClassName: 'header-class',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
          },
        );
      });
      data.body.forEach((el, indx) => {
        if (indx !== 0) {
          const d = {
            id: uuid(),
          };
          el.Data.forEach(({ VarCharValue: k }, i) => {
            d[columns[i].field] = k;
          });
          rows.push(d);
        }
      });
    }

    return {
      columns, rows,
    };
  }
}

export default new MapperClass();
