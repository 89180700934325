import React from 'react';
import { HTTP_STATUS, LOG_OPTIONS } from 'Asset/constants';
import {
  Link, PositiveButton, Radio,
} from 'Component';
import { useSelector } from 'react-redux';
import FilterHook from 'Hooks/Log/Filter.hook';
import Stack from '@mui/material/Stack';
import ListOrganization from 'Views/log/component/ListOrganization';
import ListDomain from 'Views/log/component/ListDomain';

import DatesFind from 'Views/log/component/DatesFind';
import { FormattedMessage } from 'react-intl';
import { AddTask } from '@mui/icons-material';
import AddButton from 'Views/log/component/AddButton';

function Filter() {
  const {
    loading, filter, action,
  } = useSelector((s) => s.log);

  const {
    status, onSelectStatus, company,
    onReset, onOpenAdd, visible, vFind, onFind, onSelectDate, dates, onFindNotes,
  } = FilterHook();
  return (
    <Stack sx={{ maxWidth: 320 }}>
      <Stack sx={{ flexWrap: 'wrap', justifyContent: 'space-around' }} direction="row">
        <AddButton label="find" disabled={visible && !vFind} visible={vFind} onOpenAdd={onFind} />
        <AddButton label="add" disabled={vFind && !visible} visible={visible} onOpenAdd={onOpenAdd} />
      </Stack>
      {status && <Link disabled label="reset" onClick={onReset} />}
      { (visible || vFind) && (
      <Stack>
        { action === 'find' && <DatesFind dates={dates} onSelectDate={onSelectDate} /> }
        <Radio
          loading={loading === HTTP_STATUS.PENDING}
          data={LOG_OPTIONS}
          handleChange={onSelectStatus}
          title="organization.status"
          value={status}
        />
        { status && (
        <ListOrganization data={company} />
        )}
        { status && filter.id && (
        <ListDomain data={company} />
        )}

        { filter.end !== '' && filter.init !== '' && filter.id && (
        <PositiveButton sx={{ width: 120, mt: 1, ml: 0 }} onClick={onFindNotes}>
          <FormattedMessage id="find" />
          <AddTask sx={{ ml: 1 }} />
        </PositiveButton>
        )}
      </Stack>
      )}
    </Stack>
  );
}

export default Filter;
