import React from 'react';
import Stack from '@mui/material/Stack';
import Loading from './Loading';

function GridLoading() {
  const limit = 3;
  const result = Array.from(Array(limit).keys()).map((e) => <Loading key={e} width={`${Math.floor(Math.random() * (70 - 30)) + 30}%`} />);
  return (
    <Stack>
      { result }
    </Stack>
  );
}

export default GridLoading;
