import React from 'react';
import { useSelector } from 'react-redux';
import { FontTitle } from 'Component';
import { FormattedMessage } from 'react-intl';
import DataTable from 'Component/Datagrid/DataTable';
import { COLUMNS_QUERY_TABLE_MANUAL } from 'Asset/constants';
import { v4 as uuid } from 'uuid';

function Manual() {
  const { data, table } = useSelector((s) => s.query);
  if (!table) {
    return (
      <>
        <FontTitle>
          <FormattedMessage id="must.select.table" />
        </FontTitle>
      </>
    );
  }
  return (
    <DataTable
      columns={COLUMNS_QUERY_TABLE_MANUAL}
      notData={false}
      height="60vh"
      rows={data[table].map((k) => ({ ...k, id: uuid() }))}
    />

  );
}

export default Manual;
