import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontTitle } from 'Component/Font';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import GridLoading from 'Component/Loading/GridLoading';

const Component = styled(DataGrid, {})(() => ({
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    fontSize: 16,
    padding: 10,
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
}));
export default function DataTable({
  columns = [], rows = [], loading, notData, config = {}, height,
}) {
  const t = useIntl();
  const { value: language } = useSelector((s) => s.language);
  const [data, setData] = React.useState(rows);
  const actualColumns = columns.map((col) => ({
    ...col, headerName: col.translate ? t.formatMessage({ id: col.headerName }) : col.headerName,
  }));
  React.useEffect(() => {
    if (rows.length > 0) {
      if (config && config.translate) {
        const keys = Object.keys(rows[0]);
        const act = rows.map((it) => {
          const send = {};
          keys.forEach((ac) => {
            send[ac] = it[ac];
            if (config.translate.includes(ac)) {
              send[ac] = t.formatMessage({ id: it[ac] });
            }
          });
          return send;
        });
        setData(act);
      } else {
        setData(rows);
      }
    }
  }, [rows, language]);

  if (loading) return <GridLoading />;

  if (notData) {
    return (
      <FontTitle>
        <FormattedMessage id="not.have.data" />
      </FontTitle>
    );
  }

  const setHeight = () => {
    if (!height) {
      if (loading) {
        return '40vh';
      }
      if (data.length > 20 && data.length < 40) {
        return `${data.length * 56}px`;
      }
      if (data.length <= 10) return `${data.length * 81}px`;
      if (data.length <= 20) return `${data.length * 61}px`;
      if (data.length >= 40) return '130vh';
      if (data.length >= 150) return '230vh';
      return '100vh';
    }
    return height;
  };

  return (
    <Stack sx={{
      minHeight: setHeight(),
      '& .header-class': {
        fontSize: 16,
        color: (h) => h.palette.white.main,
        bgcolor: (h) => h.palette.black.main,
        svg: {
          color: (h) => h.palette.white.main,
          bgcolor: (h) => h.palette.black.main,
        },
      },
    }}
    >
      <Component
        sx={{
          '& div[data-rowIndex][role="row"]:nth-of-type(odd)': {
            color: (th) => th.palette.common.black,
            bgcolor: (th) => th.palette.blue.first,
            fontWeight: '500',
          },
          '& div[data-rowIndex][role="row"]:nth-of-type(even)': {
            color: (th) => th.palette.common.black,
            bgcolor: (th) => th.palette.cream.first,
          },
        }}
        rowSpacingType="border"
        columns={actualColumns}
        rows={data}
        hideFooter
      />
    </Stack>
  );
}
