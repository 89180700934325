import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetadata } from 'Slice/query';
import { Divider } from 'Component/index';
import Fields from 'Views/query/Guided/Fields/Fields';
import ConditionSelected from 'Views/query/Guided/Condition/ConditionSelected';
import Condition from 'Views/query/Guided/Condition/Condition';

function Guided() {
  const dispatch = useDispatch();
  const {
    table, fields, tables,
  } = useSelector((state) => state.query);
  const { domain } = useSelector((s) => s.domain);
  React.useEffect(() => {
    if (!tables) dispatch(fetchMetadata());
  }, []);
  return (
    <>
      {table && domain && (
      <>
        <Divider />
        <Fields />
      </>
      )}
      {
              fields.length > 0 && (
              <>
                <Divider />
                <ConditionSelected />
                <Condition />
              </>
              )
          }
    </>
  );
}

export default Guided;
