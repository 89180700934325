import React from 'react';
import {
  Route, Routes as Rou, Navigate, useLocation,
} from 'react-router-dom';
import { ROUTES } from 'Asset/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActual } from 'Slice/header';

function Routes() {
  const location = useLocation();
  const { value } = useSelector((s) => s.header);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (ROUTES.ROUTE.filter((item) => item.path === location.pathname).length > 0) {
      const { path } = ROUTES.ROUTE.filter((item) => item.label === value)[0];
      if (path !== location.pathname) {
        dispatch(setActual(ROUTES.ROUTE
          .filter((item) => item.path === location.pathname)[0].label));
      }
    }
  }, [location]);
  return (
    <Rou>
      {ROUTES.ROUTE.map((props) => <Route key={props.label} {...props} />)}
      <Route path="*" element={<Navigate to="/home" />} />
    </Rou>
  );
}

export default Routes;
