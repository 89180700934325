import React from 'react';
import { Auth } from 'aws-amplify';
import Box from '@mui/material/Box';
import Navigation from 'Component/Navigation';
import Content from 'Component/ViewContainer/content/Content';
import { useNavigate } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setLogged } from 'Slice/user';

function Logged({ content, filter, isMiddle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((s) => s.user);
  React.useEffect(async () => {
    if (sessionStorage.getItem('canaima')) {
      if (!isLogged) {
        try {
          const data = await Auth.currentSession();
          if (data) dispatch(setLogged(true));
        } catch (e) {
          dispatch(logout());
          navigate('/');
        }
      }
    } else {
      dispatch(logout());
      navigate('/');
    }
  }, []);
  const idleTimerRef = React.useRef(null);
  const onIdle = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.info('logout succesfull');
    } finally {
      dispatch(logout());
      navigate('/');
    }
  };
  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={Number(process.env.SESSION_TIMEOUT)}
      onIdle={onIdle}
    >

      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Navigation />
        <Content content={content} filter={filter} isMiddle={isMiddle} />
      </Box>
    </IdleTimer>
  );
}

export default Logged;
