import React from 'react';
import { BoxComment, PositiveButton } from 'Component';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createQuery, setManualQuery } from 'Slice/query';
import Stack from '@mui/material/Stack';

function Manual() {
  const { manualQuery } = useSelector((s) => s.query);
  const t = useIntl();
  const dispatch = useDispatch();
  const onChange = (w) => dispatch(setManualQuery(w.target.value));
  const disabled = !(manualQuery.length >= 60
        && manualQuery.toUpperCase().includes('AND')
        && manualQuery.toUpperCase().includes('WHERE')
        && manualQuery.toUpperCase().includes('FROM')
        && manualQuery.toUpperCase().includes('SELECT'));
  const sendQuery = () => {
    dispatch(createQuery({ query: manualQuery }));
  };
  return (
    <>
      <BoxComment
        inputProps={{
          sx: {
            fontWeight: '600',
            color: (th) => th.palette.white.main,
          },
        }}
        placeholder={t.formatMessage({ id: 'ingress.query.manual' })}
        multiline
        fullWidth
        value={manualQuery}
        onChange={onChange}
      />
      <Stack>
        <PositiveButton
          disabled={disabled}
          sx={{ maxWidth: 120, mb: 2, mt: 1 }}
          onClick={sendQuery}
        >
          <FormattedMessage id="send.query" />
        </PositiveButton>
      </Stack>

    </>
  );
}

export default Manual;
