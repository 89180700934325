import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';

function CustomCheckBox({ checked, onChange, label }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: (theme) => theme.palette.btn.positive.main,
              '&.Mui-checked': {
                color: (theme) => theme.palette.btn.positive.main,
              },
              '&: label': {
                color: (theme) => theme.palette.btn.positive.main,
              },
            }}
          />
                )}
        sx={{
          color: (t) => t.palette.white.main,
        }}
        label={<FormattedMessage id={label} />}
      />
    </FormGroup>
  );
}

export default CustomCheckBox;
