import React from 'react';
import { NegativeButton, PositiveButton } from 'Component/Button/Custom/Base';
import { FormattedMessage } from 'react-intl';
import { AddTask, Cancel } from '@mui/icons-material';

function AddButton({
  onOpenAdd, visible, label, disabled,
}) {
  if (!visible) {
    return (
      <PositiveButton disabled={disabled} sx={{ width: 120, mb: 1, ml: 0 }} onClick={onOpenAdd}>
        <FormattedMessage id={label} />
        <AddTask sx={{ ml: 1 }} />
      </PositiveButton>
    );
  }
  return (
    <NegativeButton sx={{ width: 120, mb: 1, ml: 0 }} onClick={onOpenAdd}>
      <FormattedMessage id="cancel" />
      <Cancel sx={{ ml: 1 }} />
    </NegativeButton>
  );
}

export default AddButton;
