import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setFields } from 'Slice/query';
import Stack from '@mui/material/Stack';
import Selected from 'Views/query/Guided/Fields/Selected';
import { List as L } from 'Component';

export default function Fields() {
  const dispatch = useDispatch();
  const { fields, fieldsByTable } = useSelector((state) => state.query);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setFields(
      value,
    ));
  };
  const setDisabled = (item) => {
    if (fields.length > process.env.MAX_FIELD_SELECT) {
      return !fields.includes(item);
    }
    return false;
  };
  return (
    <Stack>
      <Selected />
      <L disabledItem={setDisabled} multiple data={fieldsByTable} handleChange={handleChange} value={fields} title="select.fields" />
    </Stack>
  );
}
