import React from 'react';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { Divider, FontTitle, TextInput } from 'Component';

// TODO se debe validar las fechas para evitar incoherencias > || <

function DatesFind({ onSelectDate, dates }) {
  return (
    <Stack>
      <FontTitle font={16}>
        <FormattedMessage id="date.init" />
      </FontTitle>
      <TextInput
        value={dates.init}
        name="init"
        type="date"
        onChange={onSelectDate}
        InputProps={{
          inputProps: {
            max: dates.end,
          },
        }}
      />
      <Divider />
      <FontTitle font={16}>
        <FormattedMessage id="date.end" />
      </FontTitle>
      <TextInput
        value={dates.end}
        name="end"
        type="date"
        onChange={onSelectDate}
        InputProps={{
          inputProps: {
            min: dates.init,
            max: new Date().toISOString().slice(0, 10),
          },
        }}
      />
    </Stack>
  );
}

export default DatesFind;
