import {
  Login, Home, Query, Log,
} from 'Views';
import React from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ToolTip from 'Component/Tooltip';
import { FormattedMessage } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuid } from 'uuid';
import { List } from '@mui/material';

export const HTTP_STATUS = Object.freeze({
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
});

export const URL = Object.freeze({
  METADATA_TABLES_QUERY: 'getmetadata',
  CREATE_QUERY: 'runqueryadmin',
  DOMAINS: 'getmatertableall',
  STATUS_COMPANY: 'companiesanddomainslist',
  CREATE_NOTE: 'insertnote',
  NOTES_LIST: 'notelist',
});

export const OPERATION_QUERY = Object.freeze({
  STRING: ['=', 'LIKE', '!='],
  BOOLEAN: ['True', 'False'],
  DATE: ['>', '<', '=', '>=', '<='],
});

export const ROUTES = Object.freeze({
  ROUTE: [
    {
      path: '/',
      element: <Login />,
      label: 'login',
    },
    {
      path: '/home',
      element: <Home />,
      label: 'home',
    },
    {
      path: '/query',
      element: <Query />,
      label: 'query',
    },
    {
      path: '/logger',
      element: <Log />,
      label: 'logger',
    },
  ],
});

export const DOMAIN_LOG_OPTIONS = Object.freeze([
  {
    value: 'A',
    label: 'actives',
  },
  {
    value: 'I',
    label: 'inactives',
  },
]);

export const LOG_OPTIONS = Object.freeze([
  {
    value: 'T',
    label: 'all',
  },
  ...DOMAIN_LOG_OPTIONS,
]);

export const COLUMNS_NOTES = Object.freeze([
  {
    field: 'type',
    headerName: 'priority',
    translate: true,
    sorteable: false,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: (params) => {
      const child = params.value !== 'UR' ? <LowPriorityIcon sx={{ color: (t) => t.palette.btn.positive.main }} />
        : <PriorityHighIcon sx={{ color: (t) => t.palette.error.main }} />;
      return (
        <ToolTip title={<FormattedMessage id={params.value} />} placement="top">
          { child}
        </ToolTip>
      );
    },
  },
  {
    field: 'note',
    headerName: 'note',
    translate: true,
    sorteable: false,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'left',
    flex: 1.5,
  },
  {
    field: 'domain',
    headerName: 'domain',
    translate: true,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'user',
    headerName: 'user',
    translate: true,
    sorteable: true,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'date',
    sorteable: true,
    translate: true,
    type: 'date',
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (p) => new Date(p.value),
    flex: 1,
  },

]);

export const COLUMNS_QUERY_TABLE_MANUAL = Object.freeze([
  {
    field: 'Name',
    headerName: 'field',
    translate: true,
    sorteable: false,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'left',
    flex: 1,
  },
  {
    field: 'Type',
    headerName: 'type',
    translate: true,
    sorteable: false,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'Options',
    headerName: 'options',
    translate: true,
    sorteable: false,
    headerClassName: 'header-class',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: (params) => {
      if (params && params.value && params.value.length > 0) {
        const child = params.value.map((it) => (
          <ListItem key={uuid()}>
            <ListItemText
              primary={it}
            />
          </ListItem>
        ));
        return (
          <List>
            {child}
          </List>
        );
      }
      return null;
    },
  },

]);
