import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createQuery, setQueryToSend } from 'Slice/query';
import Stack from '@mui/material/Stack';
import { Copy, PositiveButton } from 'Component/Button';
import { FontTitle } from 'Component/Font';
import { Divider } from 'Component/index';

function Guided() {
  const t = useIntl();
  const {
    fields, condition, table, queryToSend,
  } = useSelector((s) => s.query);
  const { domain } = useSelector((s) => s.domain);
  const dispatch = useDispatch();
  const sendQuery = () => {
    dispatch(createQuery({ query: queryToSend }));
  };
  const result = () => {
    const base = `SELECT ${fields.join(', ')} FROM ${table} WHERE dominio = '${domain}'`;
    const cond = ` AND ${condition.map((item) => {
      if (item.type === 'boolean') {
        return `${item.field} ${item.op} ${(item.value === 'True')}`;
      }
      if (item.type !== 'date') {
        return `${item.field} ${item.op} '${item.op === 'LIKE' ? `%${item.value}` : item.value}'`;
      }
      return ` CAST(SUBSTRING(${item.field},1,10) AS DATE) ${item.op} CAST('${item.value}' AS DATE)`;
    }).join(' AND ')}`;
    if (condition.length > 0) {
      return base + cond;
    }
    return base;
  };
  const translate = () => {
    const base = `SELECT ${fields.map((id) => t.formatMessage({ id })).join(', ')} FROM ${t.formatMessage({ id: table })} WHERE dominio = '${domain}'`;
    const cond = ` AND ${condition.map((item) => `${t.formatMessage({ id: item.field })} ${item.op} '${item.op === 'LIKE' ? `%${item.value}` : item.value}'`).join(' AND ')}`;
    if (condition.length > 0) {
      return base + cond;
    }
    return base;
  };
  React.useEffect(() => {
    if (domain) {
      dispatch(setQueryToSend(result()));
    }
  }, [fields, condition, table, domain]);
  if (fields.length > 0) {
    return (
      <Stack>
        <Copy text={queryToSend} tooltip="copy.query" />
        <FontTitle>
          {translate()}
        </FontTitle>
        <Divider />
        <Stack>
          <PositiveButton sx={{ maxWidth: 120, mb: 2 }} onClick={sendQuery}>
            <FormattedMessage id="send.query" />
          </PositiveButton>
        </Stack>
      </Stack>
    );
  }
  return (
    <FontTitle>
      <FormattedMessage id="ingress.query" />
    </FontTitle>
  );
}
export default Guided;
