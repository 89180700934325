import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS } from 'Asset/constants';
import DmarcServices from 'Service/dmarc.services';

const NAME_SPACE = 'log';
export const fetchOrganization = createAsyncThunk(
  `${NAME_SPACE}/fetchOrganization`,
  async () => {
    const { data } = await DmarcServices.GetOrganization('T');
    return data;
  },
);
export const addNote = createAsyncThunk(
  `${NAME_SPACE}/addNote`,
  async (note) => {
    const { data } = await DmarcServices.CreateNote(note);
    return data;
  },
);
export const findNote = createAsyncThunk(
  `${NAME_SPACE}/findNote`,
  async (note) => {
    const { data } = await DmarcServices.FindNotes(note);
    return data;
  },
);
export const INITAL_FILTER_STATE = {
  id: '',
  domain: '',
  note: '',
  type: '',
  name: '',
  init: '',
  end: '',
};

export const logSlice = createSlice({
  name: 'log',
  initialState: {
    loading: null,
    loadingCreate: null,
    loadingFind: null,
    data: null,
    filter: INITAL_FILTER_STATE,
    action: '',
    createNote: '',
  },
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
    reset: (state) => {
      state.filter = INITAL_FILTER_STATE;
    },
    setAction: (state, { payload }) => {
      state.createNote = payload === 'filter';
      state.action = payload;
    },
  },
  extraReducers: {
    [fetchOrganization.pending](state) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [fetchOrganization.fulfilled](state, { payload }) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = payload;
    },
    [fetchOrganization.rejected](state) {
      state.loading = HTTP_STATUS.REJECTED;
    },

    [addNote.pending](state) {
      state.loadingCreate = HTTP_STATUS.PENDING;
    },
    [addNote.fulfilled](state, { payload }) {
      state.loadingCreate = HTTP_STATUS.FULFILLED;
      state.createNote = false;
      state.dataCreate = payload;
      state.filter = INITAL_FILTER_STATE;
      state.action = '';
    },
    [addNote.rejected](state) {
      state.loadingCreate = HTTP_STATUS.REJECTED;
    },

    [findNote.pending](state) {
      state.loadingFind = HTTP_STATUS.PENDING;
    },
    [findNote.fulfilled](state, { payload }) {
      state.loadingFind = HTTP_STATUS.FULFILLED;

      state.dataNotes = payload;
    },
    [findNote.rejected](state) {
      state.loadingFind = HTTP_STATUS.REJECTED;
    },
  },
});
export const {
  setFilter, reset, setAction, setCreateNote,
} = logSlice.actions;
export default logSlice.reducer;
