import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DmarcServices from 'Service/dmarc.services';
import { HTTP_STATUS } from 'Asset/constants';
import MaperClass from 'Src/store/mapper/mapper.class';

const NAMESPACE = 'query';

const initialState = {
  fields: [],
  condition: [],
  table: null,
  tables: null,
  loading: null,
  fieldsByTable: [],
  data: null,
  queryToSend: null,
  resultLoading: null,
  type: false,
  manualQuery: '',
};
export const fetchMetadata = createAsyncThunk(
  `${NAMESPACE}/fetchMetadata`,
  async () => {
    const { data } = await DmarcServices.GetMetadaQuery();
    return data;
  },
);
export const createQuery = createAsyncThunk(
  `${NAMESPACE}/createQuery`,
  async (query) => {
    const { data } = await DmarcServices.CreateQuery(query);
    return data;
  },
);

export const querySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setFields: (state, action) => {
      state.fields = action.payload;
    },
    setTable: (state, action) => {
      state.table = action.payload;
      state.fields = [];
      state.condition = [];
      state.fieldsByTable = MaperClass.GetNameFields(state.data[action.payload]);
    },

    clear: (state) => {
      state.table = null;
      state.condition = [];
      state.fields = [];
    },
    setCondition: (state, { payload }) => {
      state.condition = state.condition.concat(payload);
    },
    deleteCondition: (state, { payload }) => {
      state.condition = state.condition.filter((item) => item.id !== payload);
    },
    setQueryToSend: (state, { payload }) => {
      state.queryToSend = payload;
    },
    setTypeQuery: (state) => {
      state.type = !state.type;
    },
    setManualQuery: (state, { payload }) => {
      state.manualQuery = payload;
    },

  },
  extraReducers: {
    [fetchMetadata.pending](state) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [fetchMetadata.fulfilled](state, { payload }) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = payload;
      state.tables = MaperClass.RadioButtonItems(Object.keys(payload));
    },
    [fetchMetadata.rejected](state) {
      state.loading = HTTP_STATUS.REJECTED;
    },

    [createQuery.pending](state) {
      state.resultLoading = HTTP_STATUS.PENDING;
    },
    [createQuery.fulfilled](state, { payload }) {
      state.resultLoading = HTTP_STATUS.FULFILLED;
      state.resultQuery = MaperClass.SetTableQuery(payload);
    },
    [createQuery.rejected](state) {
      state.resultLoading = HTTP_STATUS.REJECTED;
    },

  },
});

export const {
  setFields, setTable, setCondition,
  clear, deleteCondition, setQueryToSend, setTypeQuery, setManualQuery,
} = querySlice.actions;

export default querySlice.reducer;
