import { URL } from 'Asset/constants';
import http from './http.common';

class DmarcServices {
  GetDomain() {
    return http.get(URL.DOMAINS);
  }

  GetMetadaQuery() {
    return http.get(URL.METADATA_TABLES_QUERY);
  }

  CreateQuery(data) {
    return http.post(URL.CREATE_QUERY, data);
  }

  GetOrganization(status) {
    return http.get(URL.STATUS_COMPANY, { params: { status } });
  }

  CreateNote(data) {
    return http.post(URL.CREATE_NOTE, data);
  }

  FindNotes(data) {
    return http.get(URL.NOTES_LIST, { params: { ...data } });
  }
}
export default new DmarcServices();
