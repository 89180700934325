import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrganization, INITAL_FILTER_STATE, setFilter, reset, setAction, findNote,
} from 'Slice/log';
import { HTTP_STATUS } from 'Asset/constants';

const INITAL_DATE = { init: '', end: '' };
function FilterHook() {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState('');
  const [company, setCompany] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [vFind, setVFind] = React.useState(false);
  const { data, filter, loadingCreate } = useSelector((s) => s.log);
  const [dates, setDates] = React.useState(INITAL_DATE);
  React.useEffect(() => {
    if (!data) dispatch(fetchOrganization());
  }, []);
  const onReset = () => {
    setDates(INITAL_DATE);
    setStatus('');
    dispatch(reset());
  };
  const selectCompany = () => {
    if (status && data) {
      const act = [];
      Object.keys(data).forEach((key) => {
        if (status !== 'T') {
          if (data[key].status === status) {
            act.push({ ...data[key], id: key });
          }
        } else {
          act.push({ ...data[key], id: key });
        }
      });
      setCompany(act);
    }
    return null;
  };
  React.useEffect(selectCompany, [status]);
  const onSelectStatus = (e) => {
    setStatus(e.target.value);
    dispatch(setFilter({ ...INITAL_FILTER_STATE, init: filter.init, end: filter.end }));
  };
  const onOpenAdd = () => {
    onReset();
    setVisible(!visible);
    setVFind(false);
    dispatch(setAction('filter'));
  };
  const onFind = () => {
    onReset();
    setVFind(!vFind);
    setVisible(false);
    dispatch(setAction('find'));
  };

  const onSelectDate = (e) => {
    const { name, value } = e.target;
    setDates({ ...dates, [name]: value });
    dispatch(setFilter({ ...filter, [name]: value }));
  };
  const onFindNotes = () => {
    const da = {
      domain: filter.domain,
      id: filter.id,
      dateStart: filter.init,
      dateEnd: filter.end,
    };
    dispatch(findNote(da));
  };
  React.useEffect(() => {
    if (loadingCreate === HTTP_STATUS.FULFILLED) {
      setVFind(false);
      setVisible(false);
      onReset();
    }
  }, [loadingCreate]);
  return {
    onSelectStatus,
    status,
    company,
    onReset,
    onOpenAdd,
    visible,
    vFind,
    onFind,
    onSelectDate,
    dates,
    onFindNotes,
  };
}

export default FilterHook;
