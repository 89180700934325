import React from 'react';
import Stack from '@mui/material/Stack';
import {
  Check, FontTitle, PositiveButton, TextInput,
} from 'Component';

import { FormattedMessage } from 'react-intl';
import GetBreakPoint from 'Hooks/GetBreakpoint';
import { AddTask } from '@mui/icons-material';

function Note({
  value, setValue, onChange, urgent, onSend,
}) {
  const [width, setWidth] = React.useState();
  const size = GetBreakPoint({ measure: 'md' });
  React.useEffect(() => {
    if (size) {
      setWidth('65%');
    } else {
      setWidth('100%');
    }
  }, [size]);
  return (
    <Stack spacing={1}>
      <Stack sx={{ width }} spacing={2}>
        <FontTitle>
          <FormattedMessage id="add.note" />
        </FontTitle>
        <TextInput
          fullWidth
          multiline
          value={value}
          onChange={setValue}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1.2}>
        <PositiveButton disabled={value === ''} onClick={onSend}>
          <FormattedMessage id="add" />
          <AddTask sx={{ ml: 1 }} />
        </PositiveButton>
        <Check checked={urgent} onChange={onChange} label="urgent" />
      </Stack>
    </Stack>
  );
}

export default Note;
