import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDomains, setDomain } from 'Slice/domain';
import { HTTP_STATUS } from 'Asset/constants';
import { Radio } from 'Component/Button';

export default function ({ disabled }) {
  const dispatch = useDispatch();
  const { data, loading, domain } = useSelector((state) => state.domain);
  React.useEffect(() => {
    if (!data) dispatch(fetchDomains());
  }, []);
  const handleChange = (event) => {
    if (!disabled) dispatch(setDomain(event.target.value));
  };
  return (
    <Radio
      disabled={disabled}
      translate={false}
      handleChange={handleChange}
      title={disabled ? 'domains' : 'select.domain'}
      value={domain}
      data={data}
      loading={loading === HTTP_STATUS.PENDING}
    />
  );
}
