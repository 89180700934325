import React from 'react';
import Box from '@mui/material/Box';
import Navigation from 'Component/Navigation';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { logout, setLogged } from 'Slice/user';

function NoLogged({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((s) => s.user);
  React.useEffect(async () => {
    if (isLogged) {
      navigate('/home');
    } else if (sessionStorage.getItem('canaima')) {
      try {
        const data = await Auth.currentSession();
        if (data) dispatch(setLogged(true));
      } catch (e) {
        console.info('usuario no logeado', e.message);
      }
    } else {
      dispatch(logout());
    }
  }, [isLogged]);
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Navigation logged={false} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          backgroundColor: (theme) => theme.palette.bgView.main,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            paddingX: '5px !important', height: '100vh', display: 'flex',
          }}
        >
          <Grid
            container
            maxWidth="lg"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6} xs={12}>
              { children }
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default NoLogged;
